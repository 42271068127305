<template>
    <div class="add_update_org">
        <jy-dialog type="form" :title="title" :visible.sync="visible" custom-class="dialog_form" :width="global.dialogWidth" @close="close">
            <div>
                <el-form :model="diaform" ref="dialogForm" size="small" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="分拣中心名称:" prop="cName">
                        <el-input v-model="diaform.cName"></el-input>
                    </el-form-item>
                    <el-form-item label="所属机构:" prop="orgId">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.orgNa"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="监管机构" prop="mOrgId">
                        <div @click="selectMonInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.mOrgNa"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="所属行政区域:" prop="regionId">
                        <div @click="selectRegion">
                            <el-input placeholder="请选择" v-model="diaform.regionNa"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="地址" prop="addr">
                        <div @click="selectAddress">
                            <el-input placeholder="请选择" :value="diaform.addr"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="可预约时间" prop="appointT">
                        <el-time-picker is-range value-format="HH:mm:ss" v-model="diaform.appointT"></el-time-picker>
                    </el-form-item>
                    <el-form-item label="回收单价(kg/元)" prop="price">
                        <el-input v-model="diaform.price"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="diaform.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog-footer">
                <el-button type="primary" @click="save" size="small">确 定</el-button>
                <el-button @click="visible = false" size="small">取 消</el-button>
            </div>
        </jy-dialog>
        <select-addr ref="addr" :limit="1" @select="getAddr"></select-addr>
        <institutions-tree refresh :showType="showType" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>
<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import regionTree from "../../common/dialog-tree/region_tree.vue";
    import SelectAddr from "../../map/SelectAddr.vue";
    export default {
        data() {
            return {
                isEdit: false,
                title: "新增",
                diaform: {
                    cName: "",
                    orgNa: "",
                    orgId: "",
                    mOrgId: "",
                    mOrgNa: "",
                    regionId: "",
                    regionNa: "",
                    lng: "",
                    lat: "",
                    addr: "",
                    appointT: ["00:00:00", "23:59:59"],
                    remark: "",
                    price: null
                },
                rules: {
                    cName: [
                        {
                            required: true,
                            message: "请输入分拣中心名称",
                            trigger: "blur"
                        }
                    ],
                    orgId: [
                        {
                            required: true,
                            message: "请选择所属机构",
                            trigger: "change"
                        }
                    ],
                    mOrgId: [
                        {
                            required: true,
                            message: "请选择监管机构",
                            trigger: "change"
                        }
                    ],
                    regionId: [
                        {
                            required: true,
                            message: "请选择所属行政区域",
                            trigger: "change"
                        }
                    ],
                    addr: [
                        {
                            required: true,
                            message: "请选择位置",
                            trigger: "change"
                        }
                    ],
                    appointT: [
                        {
                            required: true,
                            message: "请选择可预约时间",
                            trigger: "change"
                        }
                    ],
                    price: [
                        {
                            required: true,
                            message: '请填写回收单价',
                            trigger: "change"
                        }
                    ]
                },
                showType: [0, 1, 2],
                visible: false,
                formLabelWidth: "130px"
            };
        },
        components: {
            institutionsTree,
            regionTree,
            SelectAddr
        },
        methods: {
            close() {
                this.$refs.dialogForm.resetFields();
                this.diaform.orgNa = "";
                this.diaform.mOrgNa = "";
                this.diaform.regionNa = "";
                this.diaform.lng = "";
                this.diaform.lat = "";
            },
            init(option) {
                this.visible = true;
                if (option) {
                    this.isEdit = true;
                    this.title = "编辑";
                    this.$nextTick(() => {
                        const cloneOp = JSON.parse(JSON.stringify(option));
                        if (cloneOp.appointT) {
                            cloneOp.appointT = cloneOp.appointT.split(",");
                        }
                        Object.assign(this.diaform, cloneOp);
                    });
                } else {
                    this.isEdit = false;
                    this.title = "新增";
                }
            },
            // 选择机构树
            selectInstitutions() {
                this.showType = [1];
                this.$refs.institutions.init({ flag: "org" });
            },
            selectMonInstitutions() {
                this.showType = [2];
                this.$refs.institutions.init({ flag: "mOrg" });
            },
            selectRegion() {
                this.$refs.region.init();
            },
            selectAddress() {
                this.$refs.addr.init({ lng: this.diaform.lng, lat: this.diaform.lat });
            },
            // 上级机构查询
            addInstitutions(data, flag) {
                if (flag === "org") {
                    this.diaform.orgId = data.orgId;
                    this.diaform.orgNa = data.orgNa;
                } else {
                    this.diaform.mOrgId = data.orgId;
                    this.diaform.mOrgNa = data.orgNa;
                }
            },
            //
            addRegion(data) {
                this.diaform.regionId = data.regionId;
                this.diaform.regionNa = data.regionNa;
            },
            getAddr(data) {
                this.diaform.lng = data.lng;
                this.diaform.lat = data.lat;
                this.diaform.addr = data.addr;
            },
            save() {
                this.$refs.dialogForm.validate(valide => {
                    if (valide) {
                        let url = "/unitInfo/add";
                        if (this.isEdit) {
                            url = "/unitInfo/update";
                        }
                        this.$http
                            .post(url, {
                                ...this.diaform,
                                unitType: "3",
                                appointT: this.diaform.appointT.join(",")
                            })
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.$emit("update");
                                this.visible = false;
                            });
                    }
                });
            }
        }
    };
</script>
<style scoped>
    .dialog-footer {
        text-align: center;
    }
</style>
