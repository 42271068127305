<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="机构名称" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoSortingCenterList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoSortingCenterReset')">重置</el-button>
                <el-row>
                    <el-button v-if="btnexist('wastesBasicInfoSortingCenterInsert')" size="small" type="primary" v-on:click="add">新增</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="分拣中心名称" prop="cName"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" show-overflow-tooltip></jy-table-column>
                <jy-table-column label="所属行政区域" prop="regionNa"></jy-table-column>
                <jy-table-column label="可预约委托订单时间" prop="appointT"></jy-table-column>
                <jy-table-column label="备注" prop="remark"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addupdate" @update="getList"></add-update>
        <institutions-tree :showType="[1]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import AddUpdate from "@/components/pages/admin/basic/distributionCenter/AddUpdate.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getList();
            this.setOperateList();
        },
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                let url = "/unitInfo/pageQuery";
                this.$http
                    .post(
                        url,
                        {
                            unitInfo: {
                                ...this.form,
                                unitType: "3"
                            },
                            orgIds: [],
                            mOrgIds: [],
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.unitInfo,
                                orgId: v.sysOrg ? v.sysOrg.orgId : "",
                                orgNa: v.sysOrg ? v.sysOrg.orgNa : "",
                                mOrgId: v.mSysOrg ? v.mSysOrg.orgId : "",
                                mOrgNa: v.mSysOrg ? v.mSysOrg.orgNa : ""
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$refs.addupdate.init();
            },
            handleAddUpdate(row) {
                this.$refs.addupdate.init(row);
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            handleDelete(row) {
                this.$confirm("是否删除该分拣中心?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/unitInfo/delete";
                        this.$http.post(url, row.unitId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAddUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoSortingCenterUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoSortingCenterDelete");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                form: {
                    cName: "",
                    orgId: "",
                    orgNa: ""
                },

                pageIndex: 1,
                pageSize: 10,

                operateList: [],

                btnMenuId: "wastesBasicInfoSortingCenter"
            };
        },
        components: {
            institutionsTree,
            AddUpdate
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
