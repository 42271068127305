<template>
    <el-dialog :visible.sync="visible" :title="title" @close="closeDialog">
        <div>
            <span>位置</span>
            <el-autocomplete
                size="small"
                placeholder="搜索省、市、区、道路"
                value-key="name"
                v-model="addrStr"
                :trigger-on-focus="false"
                :fetch-suggestions="searchAddr"
                @select="handleAddrSelect"
                class="searchAddr"
            ></el-autocomplete>
        </div>
        <ul>
            <li v-for="(p, index) in list" :key="p.lng + p.lat">
                <span>纬度:{{ p.lng }}</span>
                <span>经度:{{ p.lat }}</span>
                <i class="el-icon-circle-close" @click="removeMarker(index)"></i>
            </li>
        </ul>
        <div id="pointsMap"></div>
        <div class="handle_map">
            <el-button type="primary" size="small" @click="submit">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import Axios from "axios";
    export default {
        methods: {
            closeDialog() {
                this.queryStr = "";
                this.map.clearOverlays();
                this.list = [];
            },
            searchAddr(queryStr, cb) {
                Axios.get("/map/place/v2/suggestion", {
                    params: {
                        q: queryStr,
                        output: "json",
                        ak: "fbpsPAUsNE9RnaZhNApmUrjfmELngLmT",
                        region: "全国"
                    }
                }).then(res => {
                    if (res.data.status == 0) {
                        cb(res.data.result);
                    } else {
                        cb([]);
                    }
                });
            },
            handleAddrSelect({ location }) {
                const { lng, lat } = location;

                if (this.limit <= this.list.length) {
                    this.removeMarker(0);
                }
                const point = new BMap.Point(lng, lat);
                this.map.centerAndZoom(point, 19);
                this.addMarker(lng, lat);
            },
            submit() {
                if (this.list.length) {
                    if (this.limit === 1) {
                        const l = this.list[0];
                        this.$emit("select", {
                            lng: l.lng,
                            lat: l.lat,
                            addr: l.addr
                        });
                    } else {
                        this.$emit(
                            "select",
                            this.list.map(v => {
                                return {
                                    lng: v.lng,
                                    lat: v.lat,
                                    addr: v.addr
                                };
                            })
                        );
                    }
                }
                this.visible = false;
            },
            init(list) {
                this.visible = true;
                this.initMap(list);
            },
            initMap(list) {
                if (!this.map) {
                    const { lng, lat } = this.$store.state.location;

                    this.$nextTick(() => {
                        this.map = new BMap.Map("pointsMap");
                        const point = new BMap.Point(lng, lat);
                        this.map.centerAndZoom(point, 15);
                        this.map.enableScrollWheelZoom(true);

                        const _this = this;
                        this.map.addEventListener("click", function (e) {
                            if (_this.limit > _this.list.length) {
                                _this.addMarker(e.point.lng, e.point.lat);
                            } else {
                                _this.removeMarker(0);
                                _this.addMarker(e.point.lng, e.point.lat);
                            }
                        });

                        if (list && Array.isArray(list)) {
                            list.forEach(p => {
                                this.addMarker(p.lng, p.lat);
                            });
                        }
                    });
                }
            },
            addMarker(lng, lat) {
                const point = new BMap.Point(lng, lat);
                const marker = new BMap.Marker(point); // 创建标注
                this.map.addOverlay(marker); // 将标注添加到地图中
                new Promise(resolve => {
                    const pt = new BMap.Point(lng, lat);
                    new BMap.Geocoder().getLocation(
                        pt,
                        function (rs) {
                            resolve(rs);
                        },
                        {
                            poiRadius: 50,
                            numPois: 1
                        }
                    );
                }).then(rs => {
                    this.list.push({
                        m: marker,
                        lng,
                        lat,
                        addr: rs.address
                    });
                });
            },
            removeMarker(index) {
                const p = this.list[index];
                this.map.removeOverlay(p.m);
                this.list.splice(index, 1);
            }
        },
        props: {
            title: {
                type: String,
                default: "选择地址"
            },
            limit: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                visible: false,

                map: null,
                addrStr: "",
                list: []
            };
        }
    };
</script>
<style lang="scss" scoped>
    .el-autocomplete {
        width: 180px;
        margin: 10px;
    }
    .handle_map {
        text-align: center;
        margin-top: 10px;
    }
    #pointsMap {
        height: 400px;
    }
    i {
        cursor: pointer;
    }
    ul {
        margin: 10px 0;
        li {
            display: flex;
            justify-content: space-between;
            margin: 5px;
        }
    }
</style>
